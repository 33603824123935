html, body {
  margin: 0 auto;
  padding: 0;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  background-color: #F8F6FF;
  color: #322E18;
  text-align: center;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

header, footer {
  flex: 0 0 auto;
}

header {
  background-color: #322E18;
  padding: 0 5em;
  color: #CFCCD6;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}

nav ul {
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: row;
}

nav li {
  padding: 1em;
}

@media screen and (max-width: 800px) {
  header {
    padding: 0 1em;
    flex-direction: column;
    align-items: center;
  }
  nav ul {
    flex-direction: column;
  }
}

a {
  font-weight: bold;
  text-decoration: none;
  color: #847979;
}

a:hover {
  color: #B7B5E4;
}

nav a {
  color: #BBC2E2;
}

nav a:hover {
  color: white;
  font-weight: bold;
}

#wrapper {
  flex: 1 0 auto;
}

h2 {
  color: #322E18;
}

table {
  margin: 0 auto;
  min-width: 50%;
}

th, td {
  border: 0;
  padding: 1em;
}

th {
  background-color: #B7B5E4;
  color: #383568;
}

td {
  background-color: #E3E0EB;
  color: #322E18;
}

form {
  width: 45%;
  margin: 0 auto;
  padding: 0;
}

@media screen and (max-width: 800px) {
  form {
    width: 100%;
  }
}

fieldset {
  margin: 0;
  border: 2px solid #B7B5E4;
  padding: 1em;
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  grid-template-rows: repeat(5, 1fr);
  gap: .5em;
  place-items: center stretch;
}

#name, #reps, #date {
  grid-column: 2 / span 2;
}

#submit-exercise {
  grid-column: 3 / span 1;
}

input, select, button {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  padding: .5em;
  border-radius: 2px;
}

input, select {
  background-color: #CFCCD6;
  border: 0;
}

button {
  background-color: #322E18;
  font-weight: bold;
  border-top: 1px solid #847979;
  border-left: 2px solid #847979;
  border-right: 2px solid #847979;
  border-bottom: 3px solid #847979;
  color: #B7B5E4;
  font-size: 1rem;
}

button:active {
  border: 2px solid #847979;
}

footer {
  font-size: .8rem;
  padding: 5em 0 2em;
}